import {extend} from '../../../../shared/js/utils/extend';
import Swiper, { Autoplay } from 'swiper';

import SelectorEngine from '../../../../shared/js/dom/selector-engine';
import Data           from '../../../../shared/js/dom/data';
import Slider         from '../../../../shared/components/slider/slider';

// -------
// Private
// -------

const NAME      = 'hero-gallery';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const DEFAULTS = {
	grid  : {},
	slider: {
		// modules      : [Autoplay],
		slidesPerView: 1,
		spaceBetween : 0,
		autoHeight   : true,
		effect       : 'fade',
		autoplay:false,
		// autoplay     : {
		// 	delay               : 8000,
		// 	disableOnInteraction: false
		// },
		on : {
			afterInit : function() {
				const api         = this;
				const $pagination = api.el.nextSibling;
				const $bullets    = $pagination.querySelectorAll('.swiper-pagination-bullet');

				initMedia(api, $(api.slides[0]));

				setTimeout(function() {
					$bullets[0].classList.add('-animate');
				}, 100);
			},
			slideChange : function() {
				const api         = this;
				const $pagination = api.el.nextSibling;
				const $bullets    = $pagination.querySelectorAll('.swiper-pagination-bullet');
				const index       = api.activeIndex;

				initMedia(api, $(api.slides[index]));

				for (const $bullet of $bullets) {
					if($bullet.classList.contains('-animate')) {
						$bullet.classList.remove('-animate');
					}
				}

				setTimeout(function() {
					$bullets[index].classList.add('-animate');
				}, 100);
			}
		}
	}
};

const initMedia = (a, m) => {
	var
		api        = a,
		$media     = m,
		type       = $media.find('[data-type]').attr('data-type') ? $media.find('[data-type]').attr('data-type') : $media.find('[data-player]').attr('data-player'),
		pagination = $(api.el).siblings('.swiper-pagination').get(0),
		$vid       = null,
		vid        = null,
		rootDur    = '8s';

	if($('img', $media).length > 0) {
		$('img', $media).removeClass('-animate');
	}

	switch (type) {
		case 'video':
			$vid = $('video', $media).eq(0);
			vid  = $vid.get(0);

			// api.autoplay.stop();

			vid.currentTime = 0;
			vid.play();

			$vid
				.on('loadedmetadata', function() {
					rootDur = vid.duration + 's';
					pagination.style.setProperty('--wp-swiper-pagination-duration', rootDur);
				})
				.on('timeupdate', function() {
					if(vid.currentTime > vid.duration - 0.5) {
						if(vid.paused === true) {
							// setTimeout(function() {
							// 	$vid.pause();
							// }, 200);
						}
					}
				})
				.on('pause', function() {
					api.slideNext();
				});
			break;
		case 'image':
			// api.autoplay.start();
			pagination.style.setProperty('--wp-swiper-pagination-duration', rootDur);

			setTimeout(function() {
				api.slideNext();
			}, 8000);
			break;

		default:
			// api.autoplay.start();
			pagination.style.setProperty('--wp-swiper-pagination-duration', rootDur);

			setTimeout(function() {
				api.slideNext();
			}, 8000);
			break;
	}
};

// -------
// Public
// -------

const renderSlider = (element, o) => {
	Slider.single(element, o);
};

/**
 * Alle vorhandenen ´Mediengalerien´ initialisieren.
 *
 * @constructor
 */
const init = (o = {}) => {
	const _o         = extend({}, DEFAULTS, o);
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	for (const element of collection) {

		// const columns = Manipulator.getDataAttribute(element, 'columns');

		//
		// Grid
		//

		// Only by CSS!

		//
		// Slider
		//

		const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

		if (slider) {
			renderSlider(slider, _o.slider);

			// setTimeout(function() {
			// 	const $swiper     = SelectorEngine.findOne(`.swiper`, element);
			// 	const $pagination = SelectorEngine.findOne(`.swiper-pagination-bullet`, $swiper);
			// 	const sObj        = $swiper.swiper;
			//
			// 	// Initialisierungsstatus setzen.
				Data.set(element, `${DATA_KEY}.initialized`, true);
			// }, 100);
		}
	}
};

// Export
export default {
	init: init
};
