/**
 * Initial
 */

// import {scrollToElement} from '../shared/js/utils/scroll';
import {focusVisible}    from '../../shared/js/utils/focus-visible';
import {lazyImages}      from '../../shared/js/utils/lazy-load';
import {observeInView}   from '../../shared/js/utils/observer';
import {scrollPageTop} from '../../shared/js/utils/scroll';

import SelectorEngine from '../../shared/js/dom/selector-engine';
import Manipulator    from '../../shared/js/dom/manipulator';
import EventHandler   from '../../shared/js/dom/event-handler';
import Data           from '../../shared/js/dom/data';

/**
 * Accessibility (`:focus` erst bei Tastaturnavigation anzeigen.)
 */
const focusableElements = () => {
	const collection = SelectorEngine.find('input, textarea, select, label, a, button');

	for (const element of collection) {
		focusVisible.observe(element);
	}
};

/**
 * Elements in view
 */
const elementsInView = () => {
	const collection = SelectorEngine.find('.page-main > [data-ce="section"]');

	for (const element of collection) {
		EventHandler.on(element, 'enter-viewport', (event) => {
			Manipulator.addClass(event.target, '-is-shown');

			// Überwachung deaktivieren
			event.target.observer.unobserve(event.target);
		});

		// EventHandler.on(element, 'leave-viewport', (event) => {});
	}

	observeInView(collection, {
		observer: {
			root     : null,
			threshold: [0.125]
		}
	});
};

/**
 * Globaler ´Scroll top´-Button
 * Meistens positioniert am Ende des sichtbaren Viewport.
 */
const globalScrollPageTop = () => {
	const element = SelectorEngine.findOne('[data-scroll-page-top="global"]');

	if (element) {
		window.IfabGlobalScrollTop = element;

		const scrollFunc = () => {
			if (window.scrollY > window.innerHeight) {
				Manipulator.addClass(element, '-is-shown');
			} else {
				Manipulator.removeClass(element, '-is-shown');
			}
		};

		window.addEventListener('scroll', scrollFunc);
	}
};

// -------
// Public
// -------

const initial = () => {
	// Lazyload von Bildern initialisieren.
	lazyImages({
		fallback: function() {
			window.lazySizesConfig               = window.lazySizesConfig || {};
			window.lazySizesConfig.throttleDelay = 200;
			window.lazySizesConfig.lazyClass     = 'lazyload';

			const script = Manipulator.createElementFrom('<script/>');

			script.async = true;
			script.src   = `${window.PageConf.path.dist}shared/vendors/lazysizes/lazysizes.min.js`;

			Manipulator.elementAppend(script);
		}
	});

	// Accessibility (Focus).
	focusableElements();

	// Elements in view
	elementsInView();

	// Scroll to top triggers
	scrollPageTop();

	// Globaler ´Scroll top´-Button (meistens am Ende des sichtbaren Viewport)
	globalScrollPageTop();

	// ´History back´ - Buttons
	EventHandler.on(document.body, 'click', '[data-history-back]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		history.back();
	});
};

// Export
export default initial;
