/**
 * Vendors ´Cookie Consent/ Matomo´
 */

import {noop} from '../../../../shared/js/utils/index';
import {extend} from '../../../../shared/js/utils/extend';

import SelectorEngine from '../../../../shared/js/dom/selector-engine';
import EventHandler   from '../../../../shared/js/dom/event-handler';

// -------
// Private
// -------

const DEFAULTS = {
	settings : {
		Domain : '*.' + window.location.hostname,
		cookieDomain : '*.' + window.location.hostname,
		siteId       : 1,
		trackerDomain: 'https://matomo.schloss-hartenfels.de/',
		trackerFile  : 'matomo.php',
		trackerFileJs: 'matomo.js'
	}
};

const init = (o = {}) => {
	const Matomo = extend({}, DEFAULTS, o);

	window._paq = window._paq || [];
	window._paq.push(['setCookieDomain', Matomo.settings.cookieDomain]);
	window._paq.push(['setSecureCookie', true]);
	window._paq.push(['setVisitorCookieTimeout', 2880]); // 2 days
	window._paq.push(['setReferralCookieTimeout', 2880]); // 2 days
	window._paq.push(['setSessionCookieTimeout', 0]); // session only
	window._paq.push(["trackPageView"]);
	window._paq.push(["enableLinkTracking"]);

	Matomo.enable = (cb) => {
		let d = document;
		let g = d.createElement('script');
		let s = d.getElementsByTagName('script')[0];

		window._paq.push(['setTrackerUrl', Matomo.settings.trackerDomain + Matomo.settings.trackerFile]);
		window._paq.push(['setSiteId', Matomo.settings.siteId]);
		window._paq.push(['enableHeartBeatTimer', 30]);
		window._paq.push(['rememberConsentGiven']);

		g.type  = 'text/javascript';
		g.async = true;
		g.defer = true;
		g.src   = Matomo.settings.trackerDomain + Matomo.settings.trackerFileJs;

		s.parentNode.insertBefore(g, s);

		if (typeof cb === 'function')
		{
			cb();
		}
	};

	Matomo.disable = (cb) => {
		window._paq = window._paq || [];

		window._paq.push(['disableCookies']);
		window._paq.push(['deleteCookies']);
		window._paq.push(['forgetConsentGiven']);
		window._paq.push(['optUserOut']);

		if (typeof cb === 'function')
		{
			cb();
		}
	};

	return Matomo;
};

// ------
// Public
// ------

// Export
export default {
	init : init
};
