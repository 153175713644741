import {extend} from '../../../../shared/js/utils/extend';

import SelectorEngine from '../../../../shared/js/dom/selector-engine';
import Data           from '../../../../shared/js/dom/data';
import EventHandler from "../../../../shared/js/dom/event-handler";

// -------
// Private
// -------
const NAME      = 'get-ajax';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const DEFAULTS = {
	raw: {
		url       : '',
		type      : 'GET',
		data      : '',
		dataType  : 'html',
		target    : '',
		beforeSend: '',
		complete  : function() {
			console.log('COMPLETE');
		},
		success   : '',
		error     : function() {
			console.log('ERROR');
		}
	}
};


// -------
// Public
// -------

const getHtml = (o = {}, b) => {
	const _o = extend({}, DEFAULTS, o);

	_o.raw.success = function(r) {
		const parsing = $.parseHTML(r);
		const object    = $(_o.raw.target, parsing);
		const result  = {
			data	: _o.raw,
			object	: object,
			status	: 'success'
		};

		if(b && typeof b === 'function') {
			b(result);
		}
	};

	// console.log(_o);

	$.ajax(_o.raw);
};

const getForm = (o = {}, b) => {
	const _o = extend({}, DEFAULTS, o);

	_o.raw.success = function(r) {
		const parsing = $.parseHTML(r);
		const object    = $(_o.raw.target, parsing);
		const result  = {
			data	: _o.raw,
			object	: object,
			status	: 'success'
		};

		if(b && typeof b === 'function') {
			b(result);
		}
	};

	$.ajax(_o.raw);
};

// Export
export default {
	getHtml: getHtml,
	getForm: getForm
};
