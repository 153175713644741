/**
 * Layout
 */

import header       from './header/header';
import navigation   from './navigation/navigation';
import footer       from './footer/footer';
import search       from './search/search';

// ------
// Public
// ------

const layout = () => {
	header();
	navigation();
	footer();
	search();
};

// Export
export default layout;
