/**
 * Komponente ´Tag´
 */

import Data           from '../../js/dom/data';
import EventHandler   from '../../js/dom/event-handler';
import SelectorEngine from '../../js/dom/selector-engine';

// -------
// Private
// -------

const NAME      = 'tag';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const SELECTOR = `[data-c="${NAME}"]`;

// -------
// Public
// -------

/**
 * @param {Object} o
 */
const render = (o = {}) => {
	const elements = SelectorEngine.find(`${SELECTOR}`);

	for (const element of elements) {
		if(!Data.get(element, `${DATA_KEY}.initialized`)) {
			EventHandler.on(element, `click${EVENT_KEY}`, 'button', (event) => {
				EventHandler.trigger(element, `onRemove${EVENT_KEY}`, {
					event: event,
					tag  : element
				});
			});

			// Initialisierungsstatus setzen.
			Data.set(element,`${DATA_KEY}.initialized`, true);
		}
	}
};

// Export
export default {
	init: render
};
