/**
 *
 * @param {Function} func
 * @param {Number} [delay=300]
 * @param {boolean} [immediate=false]
 * @returns {(function(...[*]) : void)|*}
 */
const debounce = (func, delay = 300, immediate = false) => {
	let timerId;

	return (...args) => {
		const boundFunc = func.bind(this, ...args);

		clearTimeout(timerId);

		if (immediate && !timerId) {
			boundFunc();
		}

		const calleeFunc = immediate ? () => {
			timerId = null;
		} : boundFunc;

		timerId = setTimeout(calleeFunc, delay);
	};
};

// const debounce = (callback, wait) => {
// 	let timeoutId = null;
// 	return (...args) => {
// 		window.clearTimeout(timeoutId);
// 		timeoutId = window.setTimeout(() => {
// 			callback.apply(null, args);
// 		}, wait);
// 	};
// }

// Export
export default debounce;
