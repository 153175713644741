/**
 * Vendors ´Cookie consent´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/js/utils/scroll';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import EventHandler   from '../../../shared/js/dom/event-handler';
import Manipulator    from '../../../shared/js/dom/manipulator';

import config from '../../config/config';

import Matomo from './matomo/matomo';

// -------
// Private
// -------

// ...

// ------
// Public
// ------

const init = () => {
	let inst = null;

	if (typeof ScCookie !== 'undefined') {
		inst = new ScCookie({
			on: {
				showPreferences: function() {
					lockBodyScrolling(this);
				},
				hidePreferences: function() {
					unlockBodyScrolling(this);
				},
				saveConsent    : function(consent) {
					document.location.reload();
				},
				init           : function(consent) {
					// Youtube?
					if (this.getConsent('youtube')) {
						const collection = SelectorEngine.find('[data-player-type="youtube"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Vimeo?
					if (this.getConsent('vimeo')) {
						const collection = SelectorEngine.find('[data-player-type="vimeo"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Matomo?
					const MatomoTracking = Matomo.init();
					if (this.getConsent('matomo')) {
						MatomoTracking.enable();
					} else {
						MatomoTracking.disable();
					}
				}
			}
		});
	}

	// Video: Aktivieren und abspielen
	// EventHandler.on(document.body, 'click', '[data-privacy-enable]', (ev) => {
	// 	console.log(Manipulator.getDataAttribute(ev.delegateTarget, 'privacy-enable'));
	// });

	// Custom-Trigger für Cookie-Einstellungen anzeigen.
	EventHandler.on(document.body, 'click', '[data-privacy-settings]', (event) => {
		event.stopPropagation();

		window.ifabCookieConsent.togglePreferences();
	});

	return window.ifabCookieConsent = inst;
};

// Export
export default init;

// App.prototype.cookieConsent = function() {
// 	var root = this;
//
// 	this.CookieConsent = new ScCookie({
// 		on: {
// 			showPreferences: function() {
// 				Scroll.lockBody(this);
// 			},
// 			hidePreferences: function() {
// 				Scroll.unlockBody(this);
// 			},
// 			saveConsent    : function(consent) {
// 				document.location.reload();
// 			},
// 			init           : function(consent) {
// 				// Youtube?
// 				if (this.getConsent('youtube')) {
// 					$('[data-player-type="youtube"]').trigger(EVENT.DISABLE + '.privacyMode');
// 				}
//
// 				// Vimeo?
// 				if (this.getConsent('vimeo')) {
// 					$('[data-player-type="vimeo"]').trigger(EVENT.DISABLE + '.privacyMode');
// 				}
//
// 				// Google Analytics?
// 				// if (this.getConsent('googleanalytics')) {
// 				// 	document.cookie                  = 'ga-disable-UA-129301-1=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
// 				// 	window['ga-disable-UA-129301-1'] = false;
// 				// 	INSERT_GTM();
// 				// } else {
// 				// 	document.cookie                  = 'ga-disable-UA-129301-1=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
// 				// 	window['ga-disable-UA-129301-1'] = true;
// 				// 	INSERT_GTM();
// 				// }
// 			}
// 		}
// 	});
//
// 	root.$doc.on('click', '[data-privacy-enable]', function(ev) {
// 		ev.preventDefault();
//
// 		root.CookieConsent.togglePreferences();
//
// 		ev.stopPropagation();
// 	});
// };
