import {noop}   from './index';
import {extend} from './extend';

import Manipulator  from '../dom/manipulator';
import EventHandler from '../dom/event-handler';
import {isElement}  from './is';

// -------
// Private
// -------

/**
 * InView-Observer generieren
 *
 * @param {(HTMLElement|Element)} element
 * @param {Object} [o={}]
 * @returns {IntersectionObserver}
 */
const createInViewObserver = (element, o = {}) => {
	const observer = new IntersectionObserver(
		(entries, observer) => {
			for (const element of entries) {
				const target = element.target;

				if (element.isIntersecting) {
					Manipulator.addClass(target, '-in-view');
					EventHandler.trigger(target, 'observer-enter-viewport');
				} else {
					Manipulator.removeClass(target, '-in-view');
					EventHandler.trigger(target, 'observer-leave-viewport');
				}
			}
		},
		o.observer || {}
	);

	observer.observe(element);

	return observer;
};

// -------
// Public
// -------

/**
 * Überwache Sichtbarkeit von Elementen im Viewport.
 *
 * @param {(HTMLElement|Array)} elements
 * @param {Object} [o={}]
 */
const observeInView = (elements, o = {}) =>  {
	const collection = isElement(elements) ? [elements] : elements;
	const options    = extend({}, {
		observer       : {},
		onEnterViewport: noop,
		onLeaveViewport: noop
	}, o);

	for (const element of collection) {
		EventHandler.on(element, 'observer-enter-viewport', (event) => {
			EventHandler.trigger(event.target, 'enter-viewport');
			options.onEnterViewport(event.target);
		});

		// Verlassen des Viewport.
		EventHandler.on(element, 'observer-leave-viewport', (event) => {
			EventHandler.trigger(event.target, 'leave-viewport');
			options.onLeaveViewport(event.target);
		});

		element.observer = createInViewObserver(element, options);
	}
};

/**
 * Überwache Größenänderung von Elementen.
 *
 * @param {(HTMLElement|Array)} elements
 * @param {Object} [o={}]
 */
const observeResize = (elements, o = {}) =>  {
	// const collection = isElement(elements) ? [elements] : elements;
	// const options    = extend({}, {}, o);
	//
	// for (const element of collection) {
	//
	// }
};

export {
	observeInView,
	observeResize
};
