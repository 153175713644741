/**
 * Vendors ´Fancybox´
 */

import SelectorEngine from '../../../shared/js/dom/selector-engine';

import config from '../../config/config';

// -------
// Private
// -------

const zoomImage = () => {
	Fancybox.bind('[data-zoom="image"]', {
		Carousel: {
			Navigation: {
				nextTpl: config.icons.next,
				prevTpl: config.icons.prev
			}
		},
		// groupAttr: false,
		groupAttr: 'data-zoom-group',
		type     : 'image'
	});
};

// ------
// Public
// ------

const init = () => {
	//
	// Pluginstandards.
	//

	Fancybox.defaults.hideScrollbar = false;
	Fancybox.defaults.mainClass     = 'fancybox-custom';
	Fancybox.defaults.Thumbs        = false;
	Fancybox.defaults.l10n          =  {
		CLOSE   : window.ifabTM.translate('close'),
		DOWNLOAD: window.ifabTM.translate('download'),
		ERROR   : window.ifabTM.translate('error'),
		MODAL   : window.ifabTM.translate('closeEsc'),
		NEXT    : window.ifabTM.translate('next'),
		PREV    : window.ifabTM.translate('prev')
	};

	Fancybox.defaults.caption = function(fancybox, carousel, slide) {
		const figureCaption = SelectorEngine.next(slide.$trigger, '.figure__caption')[0];

		let caption = slide.caption || '';

		if (figureCaption) {
			caption = figureCaption.innerHTML;
		}

		return caption;
	};

	Fancybox.Plugins.Toolbar.defaults.items.close.html = config.icons.close;

	//
	// Verfügbare ´Zoomtypen´ initialisieren.
	//

	zoomImage();
};

// Export
export default init;
