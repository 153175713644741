/**
 * Komponente ´Form´
 */

import {execute} from '../../js/utils/index';
import {extend}  from '../../js/utils/extend';

import SelectorEngine     from '../../js/dom/selector-engine';
import EventHandler       from '../../js/dom/event-handler';
import Data               from '../../js/dom/data';
import Manipulator        from '../../js/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'form';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const DEFAULTS = {
	validation: {
		scrollInvalidField  : true,
	}
};

// ------
// Public
// ------

/**
 *
 * @param {HTMLElement} container
 * @param {Object} [o={}]
 */
const requiredCheckGroup = (container, o = {}) => {
	const _o = extend({}, DEFAULTS, o);
	const elements = SelectorEngine.find('[type="checkbox"], [type="radio"]', container);

	EventHandler.on(container, `handleRequired${EVENT_KEY}`, (ev) => {
		const checked = SelectorEngine.findOne(':checked', container);

		for(const element of elements) {
			Manipulator.setRequired(element, (!checked));
		}
	});

	EventHandler.on(container, `click${EVENT_KEY}`, '[type="checkbox"], [type="radio"]', (ev) => {
		EventHandler.trigger(container, `handleRequired${EVENT_KEY}`);
	});

	EventHandler.trigger(container, `handleRequired${EVENT_KEY}`);
};

/**
 *
 * @param {HTMLElement} form
 * @param {Object} o
 */
const nativeFormValidation = (form, o = {}) => {
	const _o = extend({}, DEFAULTS.validation, o);

	EventHandler.on(form, `submit${EVENT_KEY}`, (ev) => {
		if (form.checkValidity() === false) {
			const invalidFieldsets = SelectorEngine.find('fieldset:invalid', form);
			const invalidFields    = SelectorEngine.find(':invalid:not(fieldset)', form);

			ev.preventDefault();
			ev.stopPropagation();

			if (invalidFields[0] && _o.scrollInvalidField) {
				// const gsto = parseInt(getRootVar('global-scroll-top-offset'), 10);

				invalidFields[0].style.scrollMarginTop = 'var(--global-scroll-top-offset)';

				invalidFields[0].scrollIntoView({
					left    : 0,
					block   : 'start',
					behavior: 'smooth'
				});
			}

			execute(Data.get(form, 'customFormValidation'), {
				ev              : ev,
				form            : form,
				invalidFieldsets: invalidFieldsets,
				invalidFields   : invalidFields
			});
		}

		Manipulator.addClass(form, 'was-validated');
	});
};

/**
 * Initialisierung.
 *
 * @param {Object} [o={}]
 */
const init = (o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	//
	// Checkbox-, Radiogruppen (multiple & required) initialisieren
	//

	const reqCheckGroups = SelectorEngine.find('[data-check-group-required]');

	if (reqCheckGroups.length) {
		for (const group of reqCheckGroups) {
			if (!Data.get(group, `${DATA_KEY}initialized`)) {
				requiredCheckGroup(group, _o);

				// Initialisierungsstatus setzen.
				Data.set(group, `${DATA_KEY}initialized`, true);
			}
		}
	}

	//
	// Formulare initialisieren
	//

	const forms = SelectorEngine.find('form[novalidate]');

	if (forms.length) {
		for (const element of forms) {
			if (!Data.get(element, `${DATA_KEY}initialized`)) {
				nativeFormValidation(element, _o.validation);

				// Initialisierungsstatus setzen.
				Data.set(element, `${DATA_KEY}initialized`, true);
			}
		}
	}
};

// Export
export default {
	init                : init,
	nativeFormValidation: nativeFormValidation
};
