/**
 * Main(-JS-File)
 */

import {getRootVar} from '../shared/js/utils/rootvar';

import SelectorEngine from '../shared/js/dom/selector-engine';
import Manipulator    from '../shared/js/dom/manipulator';
import EventHandler   from '../shared/js/dom/event-handler';

// import config from './config/config';

import components from './components/components';
import initial    from './initial/initial';
import layout     from './layout/layout';
import vendors    from './vendors/vendors';

// --------
// Let's go
// --------

document.addEventListener('DOMContentLoaded', function(event) {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	components();
	vendors();
	initial();
	layout();

	//
	// DEV examples (nur zur Demonstration)
	//

	// ... Button

	const devButtons              = SelectorEngine.find('.static-components.-button .deck .button');
	const devButtonTriggerSpinner = SelectorEngine.findOne('#button-trigger-spinner');

	EventHandler.on(devButtonTriggerSpinner, 'click', (ev) => {
		ev.preventDefault();

		for (const element of devButtons) {
			if (!Manipulator.getDataAttribute(element, 'has-spinner')) {
				Manipulator.setDataAttribute(element, 'has-spinner', 'true');
			} else {
				Manipulator.removeDataAttribute(element, 'has-spinner');
			}
		}
	});

	// ... Tag

	const devTag = SelectorEngine.find(`[data-c="tag"]`);

	for (const element of devTag) {
		EventHandler.on(element, `onRemove.ifab.tag`, (event) => {
			const tag = event.delegateTarget;

			tag.style.opacity = '0';

			setTimeout(() => {
				tag.style.opacity = '1';
			}, 2000);
		});
	}
});

// export default function main() {
// 	document.addEventListener("DOMContentLoaded", function(event) {
// 		if (processEnv === 'development') {
// 			console.log('DEVELOPMENT'); // eslint-disable-line no-console
// 		}
//
// 		components();
// 		core();
// 	});
// }
