import {extend} from '../../js/utils/extend';

import SelectorEngine from '../../js/dom/selector-engine';
import Data           from '../../js/dom/data';
import Slider         from '../slider/slider';

// -------
// Private
// -------

const NAME      = 'media-gallery';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const DEFAULTS = {
	grid  : {},
	slider: {
		slidesPerView: 'auto',
		spaceBetween : 0,
		autoHeight   : false,
		simulateTouch: false,
		// effect       : 'flip',
		// flipEffect   : {
		// 	slideShadows: false,
		// },
	}
};

// -------
// Public
// -------

const renderSlider = (element, o) => {
	Slider.single(element, o);
};

/**
 * Alle vorhandenen ´Mediengalerien´ initialisieren.
 *
 * @constructor
 */
const init = (o = {}) => {
	const _o         = extend({}, DEFAULTS, o);
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	for (const element of collection) {
		// const columns = Manipulator.getDataAttribute(element, 'columns');

		//
		// Grid
		//

		// Only by CSS!

		//
		// Slider
		//

		const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

		if (slider) {
			renderSlider(slider, _o.slider);
		}

		// Initialisierungsstatus setzen.
		Data.set(element, `${DATA_KEY}.initialized`, true);
	}
};

// Export
export default {
	init: init
};
