/**
 * Footer
 */

import {observeInView} from '../../../shared/js/utils/observer';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Manipulator    from '../../../shared/js/dom/manipulator';
import Slider from "../../../shared/components/slider/slider";
import {extend} from "../../../shared/js/utils/extend";
import Data from "../../../shared/js/dom/data";

// -------
// Privat
// -------

const NAME      = 'footer-gallery';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;
const DEFAULTS = {
	slider: {
		slidesPerView: 'auto',
		spaceBetween : 70,
		height       : 40,
		autoHeight   : false,
		breakpoint : {
			1 : {
				spaceBetween: 0
			},
			992 : {
				spaceBetween: 70
			}
		}
	}
};
let footerContainer = null;

// -------
// Public
// -------

const renderSlider = (element, o) => {
	Slider.single(element, o);
};

const footer = (o = {}) => {
	const _o         = extend({}, DEFAULTS, o);
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	footerContainer = footerContainer || SelectorEngine.findOne('#page-footer');

	if (footerContainer)  {
		//
		// Überwachung wann der Footer im ´Viewport´ erscheint.
		//

		// Observer initialisieren.
		observeInView(footerContainer, {
			onEnterViewport: () => {
				Manipulator.addClass(document.documentElement, 'page-footer-in-view');
			},
			onLeaveViewport: () => {
				Manipulator.removeClass(document.documentElement, 'page-footer-in-view');
			},
			observer       : {
				threshold: 0
			}
		});

		for (const element of collection) {
			// const columns = Manipulator.getDataAttribute(element, 'columns');

			//
			// Grid
			//

			// Only by CSS!

			//
			// Slider
			//

			const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

			if (slider) {
				renderSlider(slider, _o.slider);

				// setTimeout(function() {
				// 	const $swiper     = SelectorEngine.findOne(`.swiper`, element);
				// 	const $pagination = SelectorEngine.findOne(`.swiper-pagination-bullet`, $swiper);
				// 	const sObj        = $swiper.swiper;
				//
				// 	// Initialisierungsstatus setzen.
				Data.set(element, `${DATA_KEY}.initialized`, true);
				// }, 100);
			}
		}
	}

	return footerContainer;
};

// Export
export default footer;
