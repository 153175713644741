/**
 * Komponente ´List´
 */

import {extend}    from '../../js/utils/extend';
import {isElement} from '../../js/utils/is';

import SelectorEngine from '../../js/dom/selector-engine';
import Data           from '../../js/dom/data';
import Manipulator    from '../../js/dom/manipulator';

import Spinner from '../spinner/spinner';

// -------
// Private
// -------

const NAME     = 'list';
const DATA_KEY = `ifab.${NAME}`;
// const API_KEY  = '.data-api';

const SELECTOR_COMPONENT = 'list';

// ------
// Public
// ------

/**
 * Listeneinträge einer geordneten Liste wrappen.
 *
 * @param {HTMLElement} [elements=null] - Element oder Elemente. Standard sind alle `<button/>`.
 */
const olWrapListItems = (elements = null) => {
	let collection = SelectorEngine.find(`ol.${SELECTOR_COMPONENT}, ol.${SELECTOR_COMPONENT} ol`);

	if (elements) {
		collection = isElement(elements) ? elements : [elements];
	}

	if (collection.length) {
		// Die zuletzt gefundenen Listen müssen zuerst abgearbeitet werden!
		collection = collection.reverse();

		for (const list of collection) {
			if (!Data.get(list, `${DATA_KEY}.initialized`)) {
				const children = SelectorEngine.children(list, 'li');

				for (const child of children) {
					child.innerHTML = `<div class="list-li-content">${child.innerHTML}</div>`;
				}

				Data.set(list, `${DATA_KEY}.initialized`, true);
			}
		}
	}
};

/**
 * Initialisierung.
 */
const init = () => {
	olWrapListItems();
};

// Export
export default {
	init           : init,
	olWrapListItems: olWrapListItems
};
