import {extend} from '../../../../../shared/js/utils/extend';

import SelectorEngine from '../../../../../shared/js/dom/selector-engine';
import Data           from '../../../../../shared/js/dom/data';
import Slider         from '../../../../../shared/components/slider/slider';
import EventHandler from "../../../../../shared/js/dom/event-handler";

// -------
// Private
// -------

const NAME      = 'nav-gallery';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const DEFAULTS = {
	grid  : {},
	slider: {
		autoHeight   : true,
		slidesPerView: 1,
		spaceBetween : 0,
		effect       : 'fade',
		on : {
			'init' : function() {
				const container = SelectorEngine.find(`[data-slideto]`, document.getElementsByClassName('.page-menu')[0]);
				const slider    = this;

				for (const link of container) {
					if(link.classList.contains('_active')) {
						triggerSlide(link, slider);
					}

					EventHandler.on(link, 'click', function() {
						triggerSlide(link, slider);
					});

					EventHandler.on(link, 'mouseover', function() {
						triggerSlide(link, slider);
					});
				}
			}
		}
	}
};

// -------
// Public
// -------

const renderSlider = (element, o) => {
	Slider.single(element, o);
};

const triggerSlide = (trigger, slider) => {
	let
		id    = trigger.getAttribute('data-slideto'),
		slide = document.querySelector('[data-slide="' + id + '"]'),
		index = Array.from(slide.parentElement.children).indexOf(slide);

	slider.slideTo(index);
};

/**
 * Alle vorhandenen ´Mediengalerien´ initialisieren.
 *
 * @constructor
 */
const init = (o = {}) => {
	const _o         = extend({}, DEFAULTS, o);
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	for (const element of collection) {

		// console.log(element);

		// const columns = Manipulator.getDataAttribute(element, 'columns');

		//
		// Grid
		//

		// Only by CSS!

		//
		// Slider
		//

		const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

		if (slider) {
			renderSlider(slider, _o.slider);
		}

		// Initialisierungsstatus setzen.
		Data.set(element, `${DATA_KEY}.initialized`, true);
	}
};

// Export
export default {
	init: init
};
