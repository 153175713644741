/**
 * Komponente ´Jumplist´
 */

import {
	isElement,
	isString
}                   from '../../js/utils/is';
import {noop}       from '../../js/utils/index';
import {extend}     from '../../js/utils/extend';
import {getRootVar} from '../../js/utils/rootvar';

import TranslationManager from '../../js/service/translation-manager';
import SelectorEngine     from '../../js/dom/selector-engine';
import Manipulator        from '../../js/dom/manipulator';
import Data               from '../../js/dom/data';
import EventHandler       from '../../js/dom/event-handler';

const tm = new TranslationManager();

// -------
// Private
// -------

const NAME      = 'jumplist';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `.data-api`;

const SELECTOR_COMPONENT = `[data-c="${NAME}"]`;

const DEFAULTS = {
	container : null,
	label     : tm.translate('jumpListMenu'),
	minTargets: 2,
	onInactive: noop,
	onActive  : noop
};

const observer = new IntersectionObserver(
	(entries, observer) => {
		for (const element of entries) {
			const target = element.target;

			if (element.isIntersecting) {
				EventHandler.trigger(target, `enter-target${EVENT_KEY}`);
			} else {
				EventHandler.trigger(target, `leave-target${EVENT_KEY}`);
			}
		}
	},
	{}
);

let originalDocTitle;

const getTargets = (element, o) => {
	let collection;

	// Zielbereiche auf Basis der Option ´container´ bestimmen.
	if (isElement(o.container)) {
		// ´container´ ist ein Elememnt.
		collection = SelectorEngine.find('[data-jumplist-name]', o.container);
	} else if (isString(o.container)) {
		// ´container´ ist ein String (CSS-Seleektor).
		const c = SelectorEngine.findOne(o.container);

		collection = SelectorEngine.find('[data-jumplist-name]', c);
	} else {
		// Kein ´container´, dann Elternelement nutzen.
		collection = SelectorEngine.find('[data-jumplist-name]', element.parentElement);
	}

	return collection;
};

/**
 * @param {HTMLElement} element
 * @param {Object} o
 */
const render = (element, o = {}) => {
	const targets = getTargets(element, o);

	let ref = element;

	// Eine ´Jump list´ nur initialisieren, wenn die Mindestanzahl an ´Targets´
	// existiert.
	if (targets.length >= o.minTargets) {
		Manipulator.setRole(element, 'navigation');
		Manipulator.setAria(
			element,
			'label',
			Manipulator.getAria(element, 'label') || o.label
		);

		// Container generieren.
		const container = Manipulator.createElementFrom('<div class="jumplist-container"/>');
		Manipulator.elementAppend(container, element);

		// Menu-Container generieren.
		const menu = Manipulator.createElementFrom('<div class="jumplist-menu"/>');
		Manipulator.elementAppend(menu, container);

		// Einträge generieren.
		for (const tgt of targets) {
			const id   = tgt.getAttribute('id');
			const text = Manipulator.getDataAttribute(tgt, 'jumplist-name');

			if (id && text) {
				const link = Manipulator.createElementFrom(`<a class="jumplist-menu__link" href="${location.href}#${id}">${text}</a>`);

				Manipulator.elementAppend(link, menu);

				//
				// Event für den Link
				//

				EventHandler.on(link, `set-active${EVENT_KEY}`, () => {
					Manipulator.addClass(link, '_active');
				});

				EventHandler.on(link, `set-inactive${EVENT_KEY}`, () => {
					Manipulator.removeClass(link, '_active');
				});

				EventHandler.on(link, `click${EVENT_KEY}`, (event) => {
					event.preventDefault();
					event.stopPropagation();

					//
					// ´Scroll top offset´ beachten/modifizieren.
					//

					const gSTO = parseInt(getRootVar('global-scroll-top-offset') || 0, 10);
					const tO = ref.offsetHeight;

					tgt.style.scrollMarginTop = `${tO + gSTO}px`;

					tgt.scrollIntoView({
						left    : 0,
						block   : 'start',
						behavior: 'smooth'
					});

					//
					// Window location anpassen.
					//

					let url = location.href;

					if (location.hash) {
						url = url.replace(location.hash, `#${id}`)
					} else {
						url = `${location.href}#${id}`;
					}

					// Seitentitel aktualisieren.
					document.title = `${originalDocTitle} (${text})`;

					// History aktualsieren.
					history.pushState({
						section: id,
						title  : document.title
					}, '', url);
				});

				//
				// Events für das Zielelement anbinden.
				//

				// Erscheint im ViewPort.
				EventHandler.on(tgt, `enter-target${EVENT_KEY}`, (event) => {
					EventHandler.trigger(link, `set-active${EVENT_KEY}`);
					o.onActive.apply(tgt, [link, event]);
				});

				// Verlässt im ViewPort.
				EventHandler.on(tgt, `leave-target${EVENT_KEY}`, (event) => {
					if (link.classList.contains('_active')) {
						EventHandler.trigger(link, `set-inactive${EVENT_KEY}`);

						o.onInactive.apply(tgt, [link, event]);
					}
				});

				observer.observe(tgt);
			}
		}
	} else {
		element.remove();

		ref = null;
	}

	return ref;
};

// -------
// Public
// -------

/**
 * Alle vorhandenen ´Jumplist´ initialisieren.
 *
 * @param {null|HTMLElement} [m=null]
 * @param {Object} [o={}]
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let collection;

	originalDocTitle = document.title;

	if (isElement(m)) {
		collection = [m];
	} else if (isString(m)) {
		collection = SelectorEngine.find(m);
	} else {
		collection = SelectorEngine.find(SELECTOR_COMPONENT);
	}

	for (const element of collection) {
		if (!Data.get(element, `${DATA_KEY}.initialized`)) {
			const tmp = render(element, _o);

			// Initialisierungsstatus setzen.
			Data.set(element, `${DATA_KEY}.initialized`, true);
		}
	}
};

// Export
export default {
	init  : init
};
