/**
 * Components
 */

// import Data           from '../../shared/js/dom/data';
// import SelectorEngine from '../../shared/js/dom/selector-engine';

import Button         from '../../shared/components/button/button';
import Details        from '../../shared/components/details/details';
import List           from '../../shared/components/list/list';
import Player         from '../../shared/components/player/player';
import Slider         from '../../shared/components/slider/slider';
import ScrollProgress from '../../shared/components/scroll-progress/scroll-progress';
import TabGroup       from '../../shared/components/tab-group/tab-group';
import MediaGallery   from '../../shared/components/media-gallery/media-gallery';
import Form           from '../../shared/components/form/form';
import Jumplist       from '../../shared/components/jumplist/jumplist';
import Tag            from '../../shared/components/tag/tag';
import RevealPassword from '../../shared/components/reveal-password/reveal-password';
import Hero 		  from './custom/hero/hero';
import MainNav 		  from './custom/nav/main/main';
import Newsletter 	  from './custom/newsletter/newsletter';

// ------
// Public
// ------

const components = () => {
	Button.init();
	List.init();
	Details.init();
	Slider.init(/*{
		breakpoints: {
			// Window >= 320x
			320: {
				spaceBetween : 16
			},
			// Window >= 768x
			768: {
				slidesPerView: 2,
				spaceBetween : 16
			},
			// Window >= 992px
			992: {
				slidesPerView: 4,
				spaceBetween : 32
			}
		}
	}*/);
	TabGroup.init({
		showIndicator: true
	});
	Player.init();
	MediaGallery.init();
	ScrollProgress.init()
	Jumplist.init();
	// const form = SelectorEngine.findOne('#exampleallfields-126');
	//
	// Data.set(form, 'customFormValidation', function(data) {
	// 	console.log(data);
	// });
	Form.init();
	Tag.init();
	RevealPassword.init();
	Hero.init();
	MainNav.init();
	Newsletter.init();
};

// Export
export default components;
