import VendorsCookieConsent from './cookie-consent/cookie-consent';
import VendorsFancybox      from './fancybox/fancybox';

const vendors = () => {
	/* eslint-disable no-console */
	// if (processEnv === 'development') {
	// 	console.log('Vendors');
	// }
	/* eslint-enable no-console */

	// @TODO: Aktivierung CookieConsent
	VendorsCookieConsent();

	VendorsFancybox();
};

export default vendors;
