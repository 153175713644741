/**
 * Navigation
 */

import {
	needJquery,
	getTransitionDuration
} from '../../../../shared/js/utils/index';

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../../shared/js/utils/scroll';

import SelectorEngine	from '../../../../shared/js/dom/selector-engine';
import Manipulator		from '../../../../shared/js/dom/manipulator';
import EventHandler		from '../../../../shared/js/dom/event-handler';
import AjaxSetup		from '../ajax/ajax';

// -------
// Private
// -------

const $              = needJquery();
const pageNewsletter = SelectorEngine.findOne('#page-newsletter-drawer');

const DEFAULTS = {
	raw: {
		url       : '',
		type      : 'POST',
		dataType  : 'html',
		target    : 'body',
		beforeSend: '',
		complete  : '',
		success   : '',
		error     : ''
	}
};

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const trigger = SelectorEngine.findOne('[aria-controls="page-newsletter-drawer"]');
	const drawer  = new Drawer(pageNewsletter, {
		useOverlay : true,
		onShow     : function(el) {
			// Hier wird eine jQuery-Instanz zurückgegeben.
			const element = el[0];

			// Scrollen des `<body/>` sperren
			lockBodyScrolling(element);

			Manipulator.addClass(trigger, '-active');
		},
		onAfterHide: function(el) {
			// Hier wird eine jQuery-Instanz zurückgegeben.
			const element = el[0];

			// Scrolen des `<body/>` entsperren.
			unlockBodyScrolling(element);

			Manipulator.removeClass(trigger, '-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageNewsletterDrawer', function(event) {
		if (this.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});

	setupNewsletter(drawer);
};

const setupNewsletter = (drawer) => {
	const form   = SelectorEngine.findOne('.sendinblue-form');
	const submit = SelectorEngine.findOne('[type="submit"]', form);
	let message  = '';

	EventHandler.on(form, 'submit', function(event) {
		event.preventDefault();

		submit.setAttribute('data-loading', true);

		const self = event.delegateTarget;

		DEFAULTS.raw.url  = self.getAttribute('action');
		DEFAULTS.raw.data = $(form).serialize();

		AjaxSetup.getForm(DEFAULTS, function(r) {
			// setTimeout(function() {
			// 	console.log('asdasdasdasdasd');
			//
			// 	EventHandler.trigger(form, 'success');
			// }, 3000);

			// if(r.status === 'success') {
			// 	EventHandler.trigger(form, 'success');
			// }
		});


		setTimeout(function() {
			message = 'success';

			EventHandler.trigger(form, 'message');

			setTimeout(function() {
				EventHandler.trigger(form, 'message');

				drawer.hide();
			}, 3000);
		}, 3000);
	});

	EventHandler.on(form, 'message', function(event) {
		const m = SelectorEngine.findOne('[data-message="' + message + '"]', form);

		if(m.classList.contains('-show')) {
			submit.removeAttribute('data-loading');
			submit.classList.remove('-hide');
			m.classList.remove('-show');

		} else {
			submit.classList.add('-hide');
			m.classList.add('-show');
		}
	});
};

// -------
// Public
// -------

const init = () => {
	setupDrawer();
};

// Export
export default {
	init : init
};
