/**
 * Komponente ´Button´
 */

import {isElement} from '../../js/utils/is';

import SelectorEngine from '../../js/dom/selector-engine';
import Data           from '../../js/dom/data';
import Manipulator    from '../../js/dom/manipulator';

import Spinner from '../spinner/spinner';

// -------
// Private
// -------

const NAME     = 'button';
const DATA_KEY = `ifab.${NAME}`;
// const API_KEY  = '.data-api';

const SELECTOR_COMPONENT = 'button';

/**
 * Monitor button changes.
 *
 * @type {MutationObserver}
 */
const observerButton = new MutationObserver((mutations) => {
	for (const mutation of mutations) {
		const element = mutation.target;

		// Spinner
		if (Manipulator.getDataAttribute(element, 'has-spinner')) {
			Spinner.addTo(element);
		} else {
			Spinner.removeFrom(element);
		}
	}
});

// ------
// Public
// ------

/**
 * Initialisierung.
 *
 * @param {HTMLElement} [elements=null] - Element oder Elemente. Standard sind alle `<button/>`.
 */
const init = (elements = null) => {
	let collection = SelectorEngine.find(SELECTOR_COMPONENT);

	if (elements) {
		collection = isElement(elements) ? elements : [elements];
	}

	if (collection.length) {
		for (const element of collection) {
			if (!Data.get(element, `${DATA_KEY}.initialized`)) {
				observerButton.observe(element, {
					attributes: true
				});

				// Initialisierungsstatus setzen.
				Data.set(element, `${DATA_KEY}.initialized`, true);
			}
		}
	}
};

// Export
export default {
	init  : init
};
