/**
 * Navigation
 */

import {
	needJquery,
	getTransitionDuration
} from '../../../shared/js/utils/index';

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/js/utils/scroll';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Manipulator    from '../../../shared/js/dom/manipulator';
import EventHandler   from '../../../shared/js/dom/event-handler';

// -------
// Private
// -------

const $        = needJquery();
const pageMenu = SelectorEngine.findOne('#page-menu-drawer');

// Klick auf einen Navigationseintrag öffnet und schließt ein Untermenü.
// Soll statt dem ´Schließen´ der Link des geklickten Navigationseintrages
// beachtet werden?
const navForceLink = false;

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const trigger = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');
	const drawer  = new Drawer(pageMenu, {
		useOverlay : true,
		onShow     : function(el) {
			// Hier wird eine jQuery-Instanz zurückgegeben.
			const element = el[0];

			// Scrollen des `<body/>` sperren
			lockBodyScrolling(element);

			Manipulator.addClass(trigger, '-active');
		},
		onAfterHide: function(el) {
			// Hier wird eine jQuery-Instanz zurückgegeben.
			const element = el[0];

			// Scrolen des `<body/>` entsperren.
			unlockBodyScrolling(element);

			Manipulator.removeClass(trigger, '-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', function(event) {
		if (this.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});
};

const handleNavigationClick = (ev) => {
	const li             = SelectorEngine.parents(ev.delegateTarget, 'li')[0];
	const hasOpenedClass = li.classList.contains('_opened');

	if (!hasOpenedClass) {
		ev.preventDefault();

		// Untermenu öffnen
		EventHandler.trigger(ev.delegateTarget, 'openMenu.ifab.navigation');
	} else {
		if (!navForceLink) {
			ev.preventDefault();

			// Untermenu schließen
			EventHandler.trigger(ev.delegateTarget, 'closeMenu.ifab.navigation');
		}
	}
};

const setupNavigation = () => {
	const uls = SelectorEngine.find('ul', pageMenu);

	for (const ul of uls) {
		// Anker des Elternelementes zum aktuellen `<ul/>`.
		const anchor     = SelectorEngine.prev(ul, 'a')[0];
		const anchorBack = SelectorEngine.children(ul, '.-back')[0];

		if (anchor) {
			EventHandler.on(anchor, 'openMenu.ifab.navigation', function(ev) {
				const ul               = SelectorEngine.next(ev.delegateTarget, 'ul')[0];
				const parentLi         = SelectorEngine.parents(ev.delegateTarget, 'li')[0];
				const parentUl         = SelectorEngine.parents(ev.delegateTarget, 'ul')[0];
				const duration         = getTransitionDuration(ul);
				const positionpageMenu = pageMenu.querySelector('.drawer__content').getBoundingClientRect().top;
				const positionUl       = this.getBoundingClientRect().top;
				const distance         = positionUl - positionpageMenu;

				Manipulator.addClass(parentUl, '_invis');
				Manipulator.addClass(parentLi, '_opened');

				if($) {
					// ul.style.marginTop = distance * (-1) + 'px';

					$(ul).slideDown((typeof ev.duration !== 'undefined') ? ev.duration : duration, function() {
						ul.style.transform = 'translate3d(0, 0, 0)';
					});
				}
			});

			EventHandler.on(anchor, 'closeMenu.ifab.navigation', function(ev) {
				const ul               = SelectorEngine.next(ev.delegateTarget, 'ul')[0];
				const parentLi         = SelectorEngine.parents(ev.delegateTarget, 'li')[0];
				const parentUl         = SelectorEngine.parents(ev.delegateTarget, 'ul')[0];
				const duration         = ev.duration || getTransitionDuration(ul);
				const positionpageMenu = pageMenu.querySelector('.drawer__content').getBoundingClientRect().top;
				const positionUl       = ev.delegateTarget.getBoundingClientRect().top;
				const distance         = positionUl - positionpageMenu;

				Manipulator.removeClass(parentUl, '_invis');
				Manipulator.removeClass(parentLi, '_opened');

				if ($) {
					// ul.style.marginTop = distance * (-1) + 'px';

					$(ul).slideUp(duration, () => {
						ul.style.transform = 'translate3d(150%,0,0)';
					});
				}
			});

			EventHandler.on(anchor, 'click.ifab.navigation', handleNavigationClick);

/*			EventHandler.on(anchor, 'click.ifab.navigation', function(ev) {
				const parentLi       = SelectorEngine.parents(ev.delegateTarget, 'li')[0];
				const hasOpenedClass = parentLi.classList.contains('_opened');



				ev.preventDefault();


				// Ein Klick auf Eintrag mit Untermenü öffnet Untermenü,
				// zweiter Klick springt zur URL!
				if (!hasOpenedClass) {
					// Untermenu öffnen
					EventHandler.trigger(ev.delegateTarget, 'openMenu.ifab.navigation');
				} else {
					// Untermenu schließen
					EventHandler.trigger(ev.delegateTarget, 'closeMenu.ifab.navigation');
				}

				// EventHandler.trigger(ev.delegateTarget, `${action}Menu.ifab.navigation`);
			});*/

			EventHandler.trigger(anchor, 'closeMenu.ifab.navigation', {
				duration : 1
			});

			if(anchorBack) {
				EventHandler.on(anchorBack, 'click', function(ev) {
					ev.preventDefault();

					EventHandler.trigger(anchor, 'click', {
						duration : 1
					});
				});
			}
		}
	}

	const activeItems = SelectorEngine.find('ul li._active > a', pageMenu);

	for (const item of activeItems) {
		EventHandler.trigger(item, 'openMenu.ifab.navigation', {
			duration : 0
		});
	}
};

// -------
// Public
// -------

const navigation = () => {
	setupDrawer();

	setupNavigation();
};

// Export
export default navigation;
